.perNotify{
    display: flex;
    height: 8vh;
    font-size: 1vw;
    width: 100vw;
    background-color: aqua;
}
.perNotify>div{
    font-weight: 400;
    /* width:8vw; */
}
.per_sno, .per_notify, .per_desc,
.per_img, .per_sent, .per_created{
    display: flex;
    align-items: center;
    color: #1C2C40;
    font-size: 1vw;
    font-weight: 600;
    padding: 20px 16px;
}
.per_sno{
    width: 2vw;
}
.per_notify{
    width: 14vw;
}
.per_desc{
    width: 17vw;
}
.per_img{
    width: 10.1vw;
}
.per_sent{
    width: 10vw;
}
.per_created{
    /* width: 18vw; */
    width: 22vw;
}
@media screen and (max-width:1250px) {
    .per_desc{
        width: 16.7vw;
    }
    .per_sent{
        width: 9.6vw;
    }
}
@media screen and (max-width:1200px) {
.per_sno{
    width: 2vw;
}
.per_notify{
    width: 13.8vw;
}
.per_desc{
    width: 16.8vw;
}
.per_img{
    width: 10vw;
}
.per_sent{
    width: 9.7vw;
}
.per_created{
    width: 22vw;
}
}
@media screen and (max-width:1170px) {
    .per_desc{
        width: 16.6vw;
    }
}
@media screen and (max-width:1150px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13.6vw;
    }
    .per_desc{
        width: 16.8vw;
    }
    .per_img{
        width: 10vw;
    }
    .per_sent{
        width: 9.5vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:1100px) {
    .per_desc{
        width: 16.4vw;
    }
}
@media screen and (max-width:1050px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13.3vw;
    }
    .per_desc{
        width: 16.3vw;
    }
    .per_img{
        width: 9.8vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:1000px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13.2vw;
    }
    .per_desc{
        width: 16vw;
    }
    .per_img{
        width: 9.6vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:970px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13.2vw;
    }
    .per_desc{
        width: 15.8vw;
    }
    .per_img{
        width: 9.6vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:940px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13.1vw;
    }
    .per_desc{
        width: 15.6vw;
    }
    .per_img{
        width: 9.5vw;
    }
    .per_sent{
        width: 9vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:910px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 13vw;
    }
    .per_desc{
        width: 15.4vw;
    }
    .per_img{
        width: 9.3vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:900px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 12.8vw;
    }
    .per_desc{
        width: 15.4vw;
    }
    .per_img{
        width: 9.3vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:850px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 12.5vw;
    }
    .per_desc{
        width: 15.4vw;
    }
    .per_img{
        width: 9.3vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:830px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 12.5vw;
    }
    .per_desc{
        width: 15vw;
    }
    .per_img{
        width: 9.3vw;
    }
    .per_sent{
        width: 9.2vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:810px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 12.4vw;
    }
    .per_desc{
        width: 15vw;
    }
    .per_img{
        width: 9vw;
    }
    .per_sent{
        width: 8.5vw;
    }
    .per_created{
        width: 22vw;
    }
         
}
@media screen and (max-width:800px) {
    .per_sno{
        width: 2vw;
    }
    .per_notify{
        width: 12.4vw;
    }
    .per_desc{
        width: 14.8vw;
    }
    .per_img{
        width: 9vw;
    }
    .per_sent{
        width: 8.5vw;
    }
    .per_created{
        width: 22vw;
    }
         
}