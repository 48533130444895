.headBar{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    background: #7A31E3;
    width: 100vw;
    height: 7vh;
    box-shadow: 0px 1px 0px rgba(192, 204, 218, 0.2);
}
.profileImage{
    width: 6vh;
    height: 6vh;
    align-self:center;
    margin-left: 1vw;
}
.logoutBtn{
    background: none;
    color: #FFFFFF;
    width: 5vw;
    height: 5vh;
    font-family: inherit;
    font-weight: 600;
    font-size: 1vw;
    align-self:center;
    margin-right: 4vw;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
}
.notificationPanel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 93vh;
    background: #CFCFCF;
}
.panelHead, .panelTitles,
.panelList, .panelPagination{
    display: flex;
    align-items: center;
}
.panelHead{
    background: #7E39E5;
    color: #FFFFFF;
    width: 90vw;
    height: 7vh;
    font-size: 1.2vw;
    font-weight: 700;
    justify-content: space-between;
    box-shadow: 0px 1px 0px #313843;
    border-radius: 4px 4px 0px 0px;
}
.headTitle{
    margin-left: 1vw;
}
.search_pushDiv, .searchDiv, .pushDiv{
    display: flex;
    height: 5vh;
    align-items: center;
    font-size: 0.9vw;
}
.searchDiv{
    background: #F9FAFC;
    border-radius: 4px;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 1vw;
}
.searchImage{
    height: 0.9vw;
}
.searchBar{
    background: none;
    outline: none;
    border: none;
    font-size: 0.9vw;
}
.pushDiv{
    display: flex;
    align-items: center;
    background: #F9FAFC;
    border-radius: 4px;
    color: #7E39E5;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 1vw;
}
.pushImage{
    padding-right: 0.5vw;
    font-size: 1.5vw;
}
.panelTitles{
    background: #FFFFFF;
    width: 90vw;
    height: 6vh;
    border-bottom: 1px solid #FFFFFF;
}
.sno, .notify, .desc,
.img, .sent, .created{
    display: flex;
    align-items: center;
    color: #1C2C40;
    font-size: 1vw;
    font-weight: 600;
    padding: 20px 16px;
}
.sno{
    width: 2vw;
}
.notify{
    width: 14vw;
}
.desc{
    width: 17vw;
}
.img{
    width: 10vw;
}
.sent{
    width: 10vw;
}
.created{
    /* width: 18vw; */
    width: 22vw;
}
.hr_line_top{
    height: 10px;
    width: 90vw;
    /* background-color: aqua; */
}
.panelList{
    background: #FFFFFF;
    width: 90vw;
    height: 64vh;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
}
.panelPagination{
    display: flex;
    justify-content: right;
    background:#FFFFFF;
    width: 90vw;
    height: 6vh;
    border-radius: 0px 0px 4px 4px;
    border-top: 1px solid #FFFFFF;
}