.create_push_page{
    display: flex;
    width: 100vw;
    height: 93vh;
    justify-content: center;
    align-items: center;
    background-color: #CFCFCF;
}
.create_push_panel{
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 91% ;
    /* height: 84vh; */
    border-radius: 4px 4px 0px 0px; 
    background-color: #FFFFFF;
}
.create_push_notifHead{
    display: flex;
    flex-direction: row;
    width: 90vw;
    /* height: 4vh; */
    /* padding-left: 3vh;
    padding-right: 3vh; */
    font-size: 1.4vw;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: 0px 1px 0px #313843; */
    border-radius: 4px 4px 0px 0px; 
}
.create_push_notifHead>p{
    padding-left: 3vh;
}
.create_push_notifDetails{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 90vw;
}
.create_push_notifInputs{
    display: flex;
    flex-direction: column;
    width: 55vw;
    padding-left: 3vh;
}

.create_push_notifTitle,
.create_push_notifDesc,
.create_push_notifImage,
.create_push_notifUrl,
.create_push_notifTarget{
    color: #1C2C40;
    font-weight: 800;
    font-size: 1vw;
}
.create_push_notifTitle>input,
.create_push_notifDesc>textarea,
.create_push_notifUrl>input,
.create_push_notifTarget>input{
    padding-left: 1vh;
}

.create_push_notifTitle{
    display: flex;
    flex-direction: column;
    width: 45vw;
    color: #1C2C40;
    font-weight: 800;
    font-size: 1vw;
}
.create_push_notifTitle>input{
    margin-top: 3px;
    height: 6vh;
    outline: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
}

.create_push_notifDesc{
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-top: 1vw;
}
.create_push_notifDesc>textarea{
    margin-top: 3px;
    height: 5vw;
    outline: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
}

.create_push_notifImage{
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-top: 1vw;   
}
.create_push_notifImage>div{
    display: flex;
    height: 6vh;
    margin-top: 3px;
    outline: none;
    justify-content: center;
    align-items: center;
    border: 1px dashed #C0CCDA;
    border-radius: 4px;
}
.create_push_notifImage>div{
    color: #8391A7;
}
#uploadFile{
    display:none;
}

.create_push_notifUrl{
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-top: 1vw;
}
.create_push_notifUrl>input{
    height: 6vh;
    margin-top: 3px;
    outline: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
}

.create_push_notifTarget{
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-top: 1vw;
}
.create_push_notifTarget>input{
    height: 6vh;
    margin-top: 3px;
    outline: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
}
.radioSingleMultiple{
    display: flex;
    flex-direction: row;
    margin-top: 3px;
}
.radioSingle, .radioMultiple{
    display: flex;
    height: 4vh;
    /* align-items: center; */
    margin-right: 1vw;
}
.radioSingle>p, .radioMultiple>p{
    margin-top: 6px;
}
.radioSingleButton, .radioMultipleButton{
    accent-color: #7A31E3;
}

.create_push_notifButtons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    width: 90vw;
    height: 10vh;
    margin-top: 1vw;
    font-weight: 500;
    background-color: #FFFFFF;
    box-shadow: 0px -1px 0px #E5E9F2;
}
.cancelBtn{
    width: 7vw;
    height: 5vh;
    margin-right: 1vw;
    border: 1px solid #7A31E3;
    border-radius: 3px;
    font-size: 2vh;
    color: #7A31E3;
    background-color: #FFFFFF;
}
.sendBtn{
    width: 7vw;
    height: 5vh;
    margin-right: 1vw;
    border: 1px solid #7A31E3;
    border-radius: 3px;
    font-size: 2vh;
    color: #FFFFFF;
    background-color: #7A31E3;
}
/* .phonebox{
    position: relative;
} */
.phone{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 32%;
    background-color: #F9FAFC;
}
.phoneImg{
    position: relative;
    height: 94%;
    width: 67%;
}
.phoneNotif{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: absolute;
    height: fit-content;
    /* width: 55%; */
    margin-top: 20%;
}
.alertNotif{
    position: relative;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8vw;
}
.cardNotif{
    display: flex;
    flex-direction: column;
    width:15.3vw;
    height: fit-content;
    padding: 5px;
    font-size: 8px;
    border-radius: 4px;
    color: #0A0909;
    background-color: #DEC8FF;
}
.cardHead{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    line-height: 1px;
}
.cardTitle{
    font-weight: 500;
    line-height: 1px;
}
.cardDesc{  
    font-weight: 400;
    line-height: 1px;
}

@media screen and (max-height: 450px) {   
    .create_push_notifTitle>input,
    .create_push_notifDesc>textarea,
    .create_push_notifImage>div,
    .create_push_notifUrl>input,
    .create_push_notifTarget>input{
        height: 5.7vh;
        font-size: 0.9vw;
    }
}
@media screen and (max-height: 400px) {
    .create_push_notifTitle>input,
    .create_push_notifDesc>textarea,
    .create_push_notifImage>div,
    .create_push_notifUrl>input,
    .create_push_notifTarget>input{
        height: 5vh;
        font-size: 0.5vw;
    } 
}