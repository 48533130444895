.loginPage{
    display: flex;
}
.loginImage{
    position: relative;
    width: 60vw;
    height: 99vh;
    object-fit: cover;
}
.signInBox{
    width: 28vw;
    height: fit-content;
    width: 100vw;
}
.signInDetails{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.signInDetails>p{
    font-weight: 700;
    font-size: 17px;
    margin-right: 23vw;
}
.email{
    width: 27vw;
    height: 5vh;
    margin-top: 3vh;
    padding: 1vh;
    border: 1px solid #E5E9F2;
    border-radius: 4px;
    outline: none;
}
.passwordDiv{
    margin-top: 2vh;
    border: 1px solid #E5E9F2;
    border-radius: 4px;
    outline: none;
}
.password{
    width: 24.6vw;
    height: 5vh;
    padding: 1vh;
    border: none;
    outline: none;
}
.eye_image{
    height: 2vh;
    padding-right: 1vw;
}
.checkboxDiv{
    display: flex;
    flex-direction: row;
    width: 28vw;
    /* height: 10vh; */
    justify-content: center;
    margin-top: 2vh;
    font-weight: 600;
    font-size: 12px;
}
.remember{
    flex: 1;
    margin-left: 1vw;
    margin-top: 2px;
}
.forgot{
    color: #5A6679;
    margin-top: 2px;
}
.signInBtn{
    background-color: #7A31E3;
    color:  #FFFFFF;
    width: 28vw;
    height: 8vh;
    margin-top: 5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-weight: 600;
    font-size: 1vw;
	font-family:inherit;
    /* border: 1px solid #E5E9F2; */
    border: none;
    border-radius: 4px;
}
@media screen and (max-width:1000px) {
    .signInDetails>p{
        /* margin-left: 3vw; */
        font-size:small
    }
    .email, .password{
        font-size: x-small;
    }
    .checkboxDiv{
        font-size: xx-small;
    }
    .signInBtn{
        font-size: x-small;
    }
    .remember,.forgot{
        margin-top: 5px;
        font-size: 8px;
    }
}